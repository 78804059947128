@import "../variables";

.address {

    &_footer {
        color: var(--theme-white-color);

        @media screen and (max-width: $media-small) {
            text-align: center;
        }

        .address__item {
            margin-bottom: 0.5rem;

            @media screen and (max-width: $media-small) {
                justify-content: center;
            }
        }

        .address__icon {
            font-size: 1rem;
            margin-right: 0.5rem;
        }

        .address__text {
            font-size: 0.875rem;
        }
    }

    &__item {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    &__icon {

    }

    &__text {

    }

}