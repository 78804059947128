@import "../variables";

@mixin h1 {
    font-weight: 700;
    font-size: 3rem;
    text-transform: uppercase;
    margin-bottom: 1rem;

    @media screen and (max-width: $media-medium) {
        font-size: 2rem;
    }

    @media screen and (max-width: $media-medium) and (orientation: landscape) {
        font-size: 1.5rem;
    }

    @media screen and (max-width: $media-small) {
        font-size: 2rem;
    }

    @media screen and (max-width: $media-small) and (orientation: landscape) {
        font-size: 1.5rem;
    }

    @media screen and (max-width: $media-extra-small) {
        font-size: 2rem;
    }

    @media screen and (max-width: $media-extra-small) and (orientation: landscape) {
        font-size: 1.125rem;
    }
}

@mixin h2 {
    font-weight: 700;
    font-size: 3rem;
}

@mixin h3 {
    font-weight: 700;
    font-size: 2.25rem;
    margin-bottom: 0.75rem;

    @media screen and (max-width: $media-small) {
        font-size: 1.75rem;
    }
}

@mixin h4 {
    font-weight: 700;
    font-size: 1.375rem;

    @media screen and (max-width: $media-small) {
        font-size: 1.15rem;
    }
}

@mixin h5 {
    font-weight: 700;
    font-size: 1.125rem;
}

@mixin h6 {

}

@mixin subtitle {
    font-size: 1.125rem;
    font-weight: 400;
}

@mixin smallText {
    font-size: 0.875rem;
    font-weight: 400;
}

@mixin blockTitleBig {
    @include h1;
    text-transform: none;
    max-width: 750px;
}

@mixin blockTitle {
    @include h3;
    text-transform: none;
    max-width: 750px;
}

@mixin blockSubTitle {
    @include subtitle;
    font-weight: 300;
    color: var(--theme-gray-color);
    line-height: 1.2;
    max-width: 750px;

    @media screen and (max-width: $media-extra-small) {
        margin-bottom: 3rem;
    }
}

@mixin mainGradientText {
    background: $default-gradient;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}