@import "../variables";
@import "../mixins/text";

.portfolio {
    margin-bottom: 3.5rem;

    &__title {
        @include blockTitle;

        &_after {
            @include h3;
            margin-bottom: 0.25rem;
            text-align: center;
            color: var(--theme-black-color);
            max-width: unset;
        }
    }

    &__text {
        @include blockSubTitle;
        margin-bottom: 3rem;

        &_after {
            font-size: 0.875rem;
            margin-bottom: 1.5rem;
            text-align: center;
            color: var(--theme-black-color);
            max-width: unset;
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 -0.5rem;
    }

    &__item {
        width: 380px;
        flex-shrink: 0;

        @media screen and (max-width: $media-large) {
            width: calc(50% - 1rem);
        }

        @media screen and (max-width: $media-extra-small) {
            width: 100%;
        }
    }

    &__button {
        display: flex;
        justify-content: center;
    }
}