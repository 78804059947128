@import "variables";

:root {
    --theme-white-color: #{$white};
    --theme-black-color: #{$black};
    --theme-red-color: #{$red};
    --theme-purple-color: #{$purple};
    --theme-green-color: #{$green};
    --theme-gray-color: #{$gray};
    --theme-gray-light-color: #{$gray-light};
    --theme-gray-high-light-color: #{$gray-high-lite};

    --theme-light-color: var(--theme-white-color);
    --theme-dark-color: var(--theme-black-color);

    --theme-portfolio-description-hover-color: var(--theme-red-color);
    --theme-portfolio-tags-color: #{$gray-semi-light};

    --theme-tariff-dark-background-color: var(--theme-gray-high-light-color);
    --theme-tariff-light-background-color: #F8F8F8;

    --theme-header-color: var(--theme-white-color);
    --theme-page-banner-background-color: var(--theme-white-color);
    --theme-page-banner-title-color: var(--theme-white-color);
    --theme-page-banner-subtitle-color: var(--theme-white-color);
    --theme-page-banner-text-color: var(--theme-white-color);
    --theme-page-banner-button-color: var(--theme-white-color);
    --theme-page-banner-arrow-start: 4rem;
    --theme-page-banner-arrow-end: 3rem;

    @media screen and (max-width: $media-medium) {
        --theme-page-banner-arrow-start: 2.5rem;
        --theme-page-banner-arrow-end: 1.5rem;
    }
}