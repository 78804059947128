@import "../variables";

.menu {
    display: flex;

    &_header {
        justify-content: center;

        @media screen and (max-width: $media-medium) {
            display: none;
        }

        .menu__item {
            margin: 0 1rem;
            font-size: 1.375rem;
            font-weight: 300;
            transition: opacity $default-transition-time;
            color: var(--theme-header-color);

            @media screen and (max-width: $media-large) {
                font-size: 1.125rem;
            }

            &:hover {
                opacity: $header-opacity;
            }
        }
    }

    &_light {
        .menu__item {
            color: var(--theme-light-color);
        }
    }

    &_dark {
        .menu__item {
            color: var(--theme-dark-color);
        }
    }

    &__item {
        text-decoration: none;
        transition: color $default-transition-time;
    }
}