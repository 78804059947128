@import "../variables";
@import "../mixins/text";

.problems-solutions {
    background: url('../images/problems-solutions-back.jpg') no-repeat top center;
    background-size: cover;
    padding: 5rem 0 3rem;
    margin-top: -3rem;

    &__content {
        margin-top: 0;
        margin-bottom: 0;
    }

    &__title {
        @include blockTitle;
    }

    &__text {
        @include blockSubTitle;
        margin-bottom: 3rem;
    }

    &__icon {
        font-size: 8.75rem;
        color: var(--theme-red-color);
        margin-bottom: 1rem;
        text-align: center;

        @media screen and (max-width: $media-small) {
            display: none;
        }

    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    &__block {
        width: 340px;
        flex-shrink: 0;
        margin: 0 40px;

        @media screen and (max-width: $media-medium) {
            width: 40%;
            margin: 0;
        }

        @media screen and (max-width: $media-small) {
            width: 100%;
            margin: 0;
        }
    }
}