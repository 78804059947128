@import "../variables";
@import "../mixins/icon";

.icon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: '#{$icon-font-family}' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &_pdf {
        @include icon($icon-pdf);
    }

    &_up-button {
        @include icon($icon-up-button);
    }

    &_map-marker-filled {
        @include icon($icon-map-marker-filled);
    }

    &_plus {
        @include icon($icon-plus);
    }
    &_minus {
        @include icon($icon-minus);
    }
    &_info-circle {
        @include icon($icon-info-circle);
    }

    &_map-marker {
        @include icon($icon-map-marker);
    }

    &_next {
        @include icon($icon-next);
    }

    &_prev {
        @include icon($icon-prev);
    }

    &_review {
        @include icon($icon-review);
    }

    &_problem {
        @include icon($icon-problem);
    }

    &_solution {
        @include icon($icon-solution);
    }

    &_problems-solutions {
        @include icon($icon-problems-solutions);
    }

    &_arrow-down {
        @include icon($icon-arrow-down);
    }

    &_phone {
        @include icon($icon-phone);
    }

    &_chart {
        @include icon($icon-chart);
    }

    &_cloud {
        @include icon($icon-cloud);
    }

    &_code {
        @include icon($icon-code);
    }

    &_edit {
        @include icon($icon-edit);
    }

    &_setting {
        @include icon($icon-setting);
    }

    &_share {
        @include icon($icon-share);
    }

    &_shield {
        @include icon($icon-shield);
    }

    &_theme {
        @include icon($icon-theme);
    }

    &_timer {
        @include icon($icon-timer);
    }

    &_users {
        @include icon($icon-users);
    }

    &_cash-register {
        @include icon($icon-cash-register);
    }

    &_code-hard {
        @include icon($icon-code-hard);
    }

    &_fill {
        @include icon($icon-fill);
    }

    &_quotes {
        @include icon($icon-quotes);
    }

    &_graduation-cap {
        @include icon($icon-graduation-cap);
    }

    &_fingerprint {
        @include icon($icon-fingerprint);
    }
}

