@import "variables";

@keyframes banner__arrow {
    0% {
        bottom: var(--theme-page-banner-arrow-start);
    }
    50% {
        bottom: var(--theme-page-banner-arrow-end);
    }
    100% {
        bottom: var(--theme-page-banner-arrow-start);
    }
}