@import "../variables";

.up-button {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--theme-gray-light-color);
    border-radius: 100%;
    cursor: pointer;
    background-color: var(--theme-white-color);
    transition: background-color,opacity $default-transition-time;
    opacity: 0;

    &_active {
        opacity: 1;
    }

    &:hover {
        .up-button__icon {
            color: var(--theme-red-color);
        }
    }

    &__icon {
        font-size: 1.125rem;
        transition: color $default-transition-time;
    }
}