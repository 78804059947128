@import "../variables";
@import "../mixins/text";

.work-stage {
    margin: 0 0.5rem 3rem 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media screen and (max-width: $media-small) {
        align-items: center;
    }

    &:hover {
        .work-stage__number {
            background: var(--theme-purple-color);
            -webkit-background-clip: text;
        }
    }

    &__number {
        font-size: 4rem;
        font-weight: 700;
        letter-spacing: -0.05em;
        @include mainGradientText;
    }

    &__title {
        @include h5;
        text-transform: uppercase;
        margin-bottom: 1.25rem;
    }

    &__text {
        line-height: 1.5;
        color: var(--theme-gray-color);

        @media screen and (max-width: $media-small) {
            text-align: center;
        }
    }

}