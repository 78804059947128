@import "../variables";

.hint {
    position: relative;

    &:not(.hint_no-hover):hover {
        .hint__text {
            display: block;
        }
    }

    &_tariffs {

        .hint__icon {
            font-size: 1.25rem;
            background: $default-gradient;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    &__icon {
        cursor: pointer;
        z-index: 10;
    }

    &__text {
        display: none;
        min-width: 390px;
        position: absolute;
        left: calc(100% + 1rem);
        top: calc(-100% - 0.6rem);
        padding: 1rem;
        color: var(--theme-white-color);
        background-color: transparentize($black, 0.2);
        border-radius: 10px;
        z-index: 9;

        &:before {
            content: "";
            position: absolute;
            right: 100%;
            top: 50%;
            border: 0.5rem solid transparent;
            border-right-color: transparentize($black, 0.2);;
            margin-top: -0.5rem;
        }
    }
}