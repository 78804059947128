@import "../variables";

.phone {
    text-decoration: none;
    display: flex;
    align-items: center;

    &_header {
        color: var(--theme-header-color);
        font-size: 1.375rem;
        font-weight: 500;
        justify-content: flex-end;

        @media screen and (max-width: $media-large) {
            font-size: 1.125rem;
        }

        .phone__icon {
            font-size: 2.125rem;
            margin-right: 0.75rem;

            @media screen and (max-width: $media-large) {
                font-size: 1.725rem;
            }

            @media screen and (max-width: $media-extra-small) {
                font-size: 2.45rem;
                margin-right: 0;
            }
        }

        .phone__text {
            transition: opacity $default-transition-time;

            @media screen and (max-width: $media-extra-small) {
                display: none;
            }

            &:hover {
                opacity: $header-opacity;
            }
        }
    }

    &_footer {
        color: var(--theme-white-color);
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 5.25rem;

        @media screen and (max-width: $media-small) {
            align-items: center;
            text-align: center;
        }

        .phone__title {
            color: var(--theme-gray-light-color);
        }

        .phone__text {
            color: var(--theme-white-color);
            transition: color $default-transition-time;
            text-decoration: none;
            font-size: 1.375rem;
            font-weight: 500;

            &:hover {
                color: var(--theme-red-color);
            }
        }
    }

    &_light {
        color: var(--theme-light-color);
    }

    &_dark {
        color: var(--theme-dark-color);
    }

    &__icon {
        color: var(--theme-red-color);
    }

    &__title {
        font-size: 0.875rem;
        line-height: 1.5;
    }

    &__text {
        transition: color $default-transition-time;
    }
}