

/* Цвета*/
$white: #ffffff;
$black: #282828;
$red: #EE2D3A;
$green: #219653;
$gray: #5A5A5A;
$gray-semi-light: #9A9A9A;
$gray-light: #C4C4C4;
$gray-high-lite: #F2F2F2;
$purple: #8e2778;

/* Градиент */
$default-gradient: linear-gradient(207deg, #EA462C 0%, #E92F32 14%, #AC2062 50%, #6F2E8F 100%);
$animated-gradient: linear-gradient(207deg, #EA462C 0%, #E92F32 7%, #AC2062 25%, #6F2E8F 100%);

/* Разное */
$header-height: 6rem;
$header-opacity: 0.7;
$default-transition-time: 0.4s;

$page-banner-content-width: 1280px;

/* Брейкпоинты для адаптива */
$media-extra-small: 575px;
$media-small: 767px;
$media-medium: 991px;
$media-large: 1199px;
$media-extra-large: 1399px;

/* Брейкпоинты для адаптива для отдельных элементов из-за особенностей макета*/
$media-header-logo-title: 1600px;

/* Шрифты */
$roboto-font-path: "fonts/roboto" !default;
$open-sans-font-path: "fonts/open-sans" !default;
$primary-font-family: "Roboto";
$html-font-size: 16px;

/* Иконочный шрифт */
$icon-font-family: "zebrus-icons" !default;
$icon-font-path: "fonts/zebrus-icons" !default;

$icon-cash-register: "\e90c";
$icon-code-hard: "\e90d";
$icon-fill: "\e90e";
$icon-quotes: "\e90f";
$icon-graduation-cap: "\e910";
$icon-fingerprint: "\e911";
$icon-cloud: "\e901";
$icon-arrow-down: "\e90b";
$icon-phone: "\e90a";
$icon-chart: "\e900";
$icon-code: "\e902";
$icon-edit: "\e903";
$icon-setting: "\e904";
$icon-share: "\e905";
$icon-shield: "\e906";
$icon-theme: "\e907";
$icon-timer: "\e908";
$icon-users: "\e909";
$icon-problems-solutions: "\e912";
$icon-problem: "\e913";
$icon-solution: "\e914";
$icon-review: "\e915";
$icon-next: "\e916";
$icon-prev: "\e917";
$icon-map-marker: "\e918";
$icon-info-circle: "\e919";
$icon-plus: "\e91a";
$icon-minus: "\e91b";
$icon-map-marker-filled: "\e91c";
$icon-up-button: "\e91d";
$icon-pdf: "\e91e";
