@import "../variables";

.header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &_default {
        height: $header-height;
        width: 100%;
        padding: 1.5rem;
        position: absolute;
        z-index: 3;

        @media screen and (max-width: $media-medium) {
            padding: 0.5rem 1.5rem;
        }
    }
}