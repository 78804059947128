@import "../variables";
@import "../mixins/text";

.feature {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0 0.5rem;
    margin-bottom: 4.5rem;

    @media screen and (max-width: $media-extra-small) {
        display: grid;
        grid-template-areas: "icon name"
                             "icon text";
        grid-template-columns: 7rem 1fr;
        text-align: left;
        margin-bottom: 3rem;
    }

    &_text {
        align-items: start;
        margin-bottom: 0;

        @media screen and (max-width: $media-large) {
            margin-bottom: 3rem;
        }

        @media screen and (max-width: $media-extra-small) {
            display: flex;
            align-items: center;
        }

        .feature__icon {
            font-size: 6.25rem;
            font-weight: 900;
            margin-bottom: 0;
            letter-spacing: -0.25rem;

            @media screen and (max-width: $media-extra-small) {
                font-size: 5rem;
                margin-right: 0;
            }
        }

        .feature__name {
            @include h3;
            text-align: left;
            line-height: 1;

            @media screen and (max-width: $media-extra-small) {
                text-align: center;
            }
        }
    }

    &:hover {
        .feature__icon:after {
            opacity: 1;
        }
    }

    &__icon {
        font-size: 5rem;
        color: $red;
        margin-bottom: 1.5rem;
        display: flex;
        justify-content: center;
        position: relative;

        @media screen and (max-width: $media-extra-small) {
            grid-area: icon;
            margin-bottom: 0;
            margin-right: 2rem;
        }

        &:after {
            opacity: 0;
            position: absolute;
            transition: opacity $default-transition-time;
            background: $default-gradient;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    &__name {
        @include subtitle;
        color: $black;
        margin-bottom: 1.25rem;

        @media screen and (max-width: $media-extra-small) {
            grid-area: name;
        }
    }

    &__text {
        @include smallText;
        color: $gray;

        @media screen and (max-width: $media-extra-small) {
            grid-area: text;
        }
    }

}