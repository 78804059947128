@import "../variables";

.simple-banner {
    background: var(--theme-red-color);
    padding: 2rem 0;

    &_image {

    }

    &__content {
        padding: 0 3rem;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media screen and (max-width: $media-large) {
            padding: 0;
        }
    }

    &__image {
        width: 100%;
        height: auto;
        max-height: 100%;
    }

    &__text {
        font-size: 1.125rem;
        font-style: italic;
        color: var(--theme-white-color);
        text-align: center;
        max-width: 905px;
        margin-top: 0.5rem;
        padding: 0 1rem;
    }
}