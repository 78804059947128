@import "../variables";
@import "../mixins/text";

.problems-solutions-block {
    margin-bottom: 2rem;

    &__title {
        @include h4;
        text-transform: uppercase;
        margin-bottom: 2.5rem;
    }

    &__item {
        margin-bottom: 2rem;
        line-height: 1.5;
        display: flex;
        align-items: flex-start;

        &_problem {

        }

        &_solution {

        }
    }

    &__icon {
        font-size: 1.25rem;
        margin-right: 1rem;

        &_problem {
            color: var(--theme-red-color);
        }

        &_solution {
            color: var(--theme-green-color);
        }
    }
}