@import "../variables";
@import "../mixins/text";

.service {
    display: grid;
    grid-template-areas: "icon name"
                         "list list";
    grid-template-columns: 2.5rem 1fr;
    grid-template-rows: 2.5rem 1fr;
    grid-column-gap: 1rem;
    padding: 2rem 1.5rem;
    margin: 0.5rem;
    position: relative;
    overflow: hidden;

    &:hover {
        .service__background {
            top: -100%;
        }
    }

    &__background {
        background: $animated-gradient;
        width: 100%;
        height: 200%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        transition: top 0.5s;
    }

    &__icon {
        grid-area: icon;
        font-size: 2.5rem;
        color: $white;
        align-self: center;
    }

    &__name {
        grid-area: name;
        @include h4;
        color: $white;
        text-transform: uppercase;
        align-self: center;
    }

    &__list {
        grid-area: list;
        margin: 2rem 0;
        padding-left: 2rem;
        align-self: start;
    }

    &__list-item {
        color: $white;
        margin-bottom: 0.75rem;
    }

    &__list-name {
        color: $white;
        line-height: 1.5rem;

        &_link {
            &:hover {
                color: $white;
                opacity: .8;
            }
        }
    }
}