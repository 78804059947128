@import "../variables";

.client {
    text-decoration: none;
    height: 105px;
    border-bottom: 5px solid var(--theme-gray-high-light-color);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all $default-transition-time;
    position: relative;

    &:hover {
        border-color: var(--theme-red-color);

        .client__logo {
            opacity: 1;

            &_gray {
                opacity: 0;
            }
        }
    }

    &__logo {
        height: 100%;
        width: auto;
        max-width: 100%;
        transition: all $default-transition-time;
        opacity: 0;

        &:hover {
            opacity: 1;
        }

        &_gray {
            position: absolute;
            opacity: 1;

            &:hover {
                opacity: 0;
            }
        }
    }
}