@import "../variables";
@import "../mixins/text";

.banner-item {

    &_page-title {
        width: 100%;
        height: 100%;

        h1 {
            z-index: 2;
            margin: 0;
        }

        .banner-item__content {
            width: $page-banner-content-width;
            max-width: 100%;
            height: 100%;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            margin: 0 auto;
            padding: 0 3rem;

            @media screen and (max-width: $media-small) {
                justify-content: flex-start;
            }

            @media screen and (max-width: $media-extra-small) {
                padding: 0 2rem;
                justify-content: center;
            }
        }

        .banner-item__image {
            position: absolute;
            width: 100%;
            height: 100%;
            bottom: 0;
            right: 0;
            z-index: 0;
            background-repeat: no-repeat;
            background-position: bottom right;
            background-size: contain;
        }

        .banner-item__title {
            color: var(--theme-page-banner-title-color);
            @include h1;
            line-height: 1.16;
            z-index: 2;
            margin-top: -$header-height;
            margin-bottom: 0;

            @media screen and (max-width: $media-extra-small) {
                margin-bottom: 1rem;
            }

            @media screen and (max-width: $media-medium) and (orientation: landscape) {
                margin-top: 0;
            }
        }

        .banner-item__subtitle {
            color: var(--theme-page-banner-subtitle-color);
            @include h1;
            line-height: 1.16;
            margin-bottom: 1.5rem;
            z-index: 2;

            @media screen and (max-width: $media-medium) and (orientation: landscape) {
                margin-bottom: 0.5rem;
            }
        }

        .banner-item__text {
            color: var(--theme-page-banner-text-color);
            font-size: 1.125rem;
            z-index: 2;
            max-width: 70%;
            margin-bottom: 3rem;
            line-height: 1.43;

            @media screen and (max-width: $media-extra-small) {
                max-width: unset;
            }

            @media screen and (max-width: $media-medium) and (orientation: landscape) {
                font-size: 0.875rem;
                margin-bottom: 1rem;
            }
        }

        .banner-item__button {
            color: var(--theme-page-banner-button-color);
            border-color: var(--theme-page-banner-button-color);
            background-color: transparent;
            z-index: 2;
            transition: background-color,color $default-transition-time;

            &:hover {
                color: var(--theme-page-banner-background-color);
                background-color: var(--theme-page-banner-button-color);
            }
        }

    }
}