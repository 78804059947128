@import "../variables";
@import "../mixins/text";

.services {
    margin-bottom: 3.5rem;

    &__title {
        @include blockTitle;
    }

    &__text {
        @include blockSubTitle;
        margin-bottom: 3rem;
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 -0.5rem;
    }

    &__item {
        width: 380px;
        flex-shrink: 0;

        @media screen and (max-width: $media-large) {
            width: calc(50% - 1rem);
        }

        @media screen and (max-width: $media-extra-small) {
            width: 100%;
        }
    }
}