@import "variables";

*,
:after,
:before {
    box-sizing: border-box;
}

html,
body {
    &.loaded {
        opacity: 1;
    }
}

html {
    font-family: $primary-font-family;
    font-size: $html-font-size;
    color: var(--theme-black-color);
    line-height: 1.16;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    padding: 0;
    margin: 0;
    //background: #142A63;
}

a {
    color: $black;
    transition: color $default-transition-time;

    &:hover {
        color: $red;
    }
}

.fancybox_hint {
    .fancybox__content {
        color: var(--theme-white-color);
        background-color: transparentize($black, 0.2);
    }
}

.test {
    display: flex;
    background: #EE2D3A;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
    cursor: pointer;
    font-size: 500px;
    width: 500px;
    height: 500px;

    &:after {
        opacity: 0;
        position: absolute;
        transition: opacity 0.3s;
        background: linear-gradient(207.12deg, #EA462C 0%, #E92F32 13.54%, #AC2062 49.48%, #6F2E8F 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    &:hover:after {
        opacity: 1;
    }
}

.b24-widget-button-position-bottom-left {
    bottom: 0.6875rem !important;
    left: 1rem !important;
}