@import "../variables";
@import "../mixins/text";

.reviews {

    &_slider {
        padding: 0 5rem;
        position: relative;

        @media screen and (max-width: $media-medium) {
            padding: 0 4rem;
        }

        @media screen and (max-width: $media-extra-small) {
            padding: 0 0 2.5rem 0;
        }

        .reviews__title {
            margin-left: -5rem;

            @media screen and (max-width: $media-extra-small) {
                margin-left: 0;
            }
        }

        .reviews__text {
            margin-left: -5rem;

            @media screen and (max-width: $media-extra-small) {
                margin-left: 0;
            }
        }
    }

    &__title {
        @include blockTitle;
    }

    &__text {
        @include blockSubTitle;
        margin-bottom: 3rem;
    }

    &__swiper {
        width: 100%;
        height: 500px;
    }

    &__button {
        font-size: 2.75rem;
        color: var(--theme-gray-light-color);
        transition: color $default-transition-time;

        @media screen and (max-width: $media-extra-small) {
            display: none;
        }

        &:hover {
            color: var(--theme-red-color);
        }

        &_prev {
            @media screen and (max-width: $media-medium) {
                left: 5px;
            }
        }

        &_next {
            @media screen and (max-width: $media-medium) {
                right: 5px;
            }
        }

        &:after {
            display: none;
        }
    }

    &__pagination {
        display: none;
        justify-content: center;
        --swiper-theme-color: var(--theme-red-color);
        --swiper-pagination-bullet-width: 0.75rem;
        --swiper-pagination-bullet-height: 0.75rem;

        @media screen and (max-width: $media-extra-small) {
            display: flex;
        }
    }
}