@import "../variables";
@import "../mixins/text";

.faq {
    &__title {
        @include blockTitle;
    }

    &__text {
        @include blockSubTitle;
        margin-bottom: 3rem;
    }
}