@import "../variables";
@import "../mixins/text";

.clients {

    &__title {
        @include blockTitle;
    }

    &__text {
        @include blockSubTitle;
        margin-bottom: 3rem;
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    &__item {
        width: calc(100% / 6);
        flex-shrink: 0;

        @media screen and (max-width: $media-large) {
            width: 25%;
        }

        @media screen and (max-width: $media-medium) {
            width: 33.33333%;
        }

        @media screen and (max-width: $media-extra-small) {
            width: 50%;
        }
    }
}