@import "../variables";
@import "../animations";

.banner {


    &_page-title {
        background: var(--theme-page-banner-background-color);
        width: 100%;
        height: 100vh;
        padding-top: $header-height;

        @media screen and (max-width: $media-large) {
            font-size: 1.125rem;
            height: calc(var(--vh, 1vh) * 100);
        }

        .banner__content {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            position: relative;
        }

        .banner__arrow {
            position: absolute;
            color: var(--theme-page-banner-text-color);
            bottom: var(--theme-page-banner-arrow-start);
            font-size: 3.2rem;
            cursor: pointer;
            padding: 0.5rem;
            animation: banner__arrow 1.5s linear infinite;

            @media screen and (max-width: $media-medium) {
                font-size: 2.25rem;
                bottom: var(--theme-page-banner-arrow-end);
            }
        }
    }
}