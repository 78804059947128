@import "../variables";

.button {
    display: flex;
    font-size: 1.25rem;
    font-weight: 900;
    text-transform: uppercase;
    border: 0.125rem solid transparent;
    border-radius: 1.5rem;
    padding: 0.75rem 1rem;
    text-decoration: none;
    cursor: pointer;
    line-height: 1;
    width: -moz-fit-content;
    width: fit-content;
    transition: background-color, color $default-transition-time;

    @media screen and (max-width: $media-medium) {
        font-size: 1rem;
    }

    @media screen and (max-width: $media-small) {
        font-size: 1rem;
    }

    @media screen and (max-width: $media-extra-small) {
        font-size: 1rem;
    }

    &_small {
        padding: 0.5rem 0.75rem;
    }

    &_red {
        background: var(--theme-red-color);
        border-color: var(--theme-red-color);
        color: var(--theme-white-color);

        &:hover {
            background: transparent;
            color: var(--theme-red-color);
        }
    }

    &_red-border {
        background: var(--theme-white-color);
        border-color: var(--theme-red-color);
        color: var(--theme-red-color);

        &:hover {
            background: var(--theme-red-color);
            color: var(--theme-white-color);
        }
    }

    &_white-border {
        background: transparent;
        border-color: var(--theme-white-color);
        color: var(--theme-white-color);

        &:hover {
            background: var(--theme-white-color);
            color: var(--theme-red-color);
        }
    }
}