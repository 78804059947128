@import "../variables";
@import "../mixins/text";

.cta-block {
    background-color: var(--theme-gray-high-light-color);
    padding: 2.5rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 3rem;

    &_with-content {
        background-color: transparent;
        flex-direction: row;
        align-items: stretch;
        padding: 0;
        flex-wrap: wrap;
        justify-content: flex-start;
        text-align: unset;

        @media screen and (max-width: $media-medium) {
            flex-wrap: wrap;
        }
    }

    &_in {
        margin-bottom: 0;
        padding: 2rem;
        flex: 1;

        @media screen and (max-width: $media-medium) {
            width: 100%;
        }
    }

    &__block-title {
        width: 100%;
        flex-shrink: 0;
        @include blockTitle;
        margin-bottom: 3rem;
    }

    &__content {
        max-width: 100%;
        width: 785px;
        padding: 2.5rem;
        margin-right: 1.5rem;
        display: flex;
        align-content: center;
        border: 10px solid var(--theme-red-color);
        text-align: left;
        flex-shrink: 0;

        @media screen and (max-width: $media-large) {
            width: 60%;
        }

        @media screen and (max-width: $media-medium) {
            width: 100%;
            padding: 1.5rem;
            margin-right: 0;
            margin-bottom: 1.5rem;
        }


        ul {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                display: flex;
                font-size: 1.125rem;
                line-height: 1.25;
                align-items: center;
                margin-bottom: 1rem;

                &:last-child {
                    margin-bottom: 0;
                }

                &:before {
                    content: "";
                    width: 18px;
                    height: 18px;
                    border-radius: 18px;
                    margin-right: 1.5rem;
                    background-color: var(--theme-gray-high-light-color);
                    flex-shrink: 0;
                }
            }
        }
    }

    &__title {
        @include blockTitle;
    }

    &__sub-title {
        font-size: 2rem;
        line-height: 1.2;
        margin-bottom: 0.75rem;

        @media screen and (max-width: $media-small) {
            font-size: 1.5rem;
        }
    }

    &__button {
        margin-bottom: 1rem;
    }

    &__text {
        font-size: 1.125rem;
        color: var(--theme-gray-color);

        a {
            text-decoration: none;
            color: var(--theme-gray-color);
        }
    }
}