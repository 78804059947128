@import "../variables";

.content {
    width: 1280px;
    max-width: 100%;
    padding: 0 3rem;
    margin: 3.5rem auto;

    @media screen and (max-width: $media-large) {
        padding: 0 3rem;
    }

    @media screen and (max-width: $media-medium) {
        padding: 0 2rem;
    }

    @media screen and (max-width: $media-small) {
        padding: 0 1.5rem;
    }

    @media screen and (max-width: $media-extra-small) {
        padding: 0 1.5rem;
    }
}