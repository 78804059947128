@import "../variables";
@import "../mixins/text";

.review {
    border: 10px solid var(--theme-gray-high-light-color);
    padding: 0.75rem 1.5rem;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    position: relative;

    @media screen and (max-width: $media-medium) {
        padding: 0.75rem 1rem;
    }

    &__quotes {
        font-size: 3.75rem;
        color:  var(--theme-gray-high-light-color);
        width: 50%;
        height: 72px;
        margin-bottom: 0.75rem;
    }

    &__logo {
        width: 50%;
        height: 72px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: -0.75rem;
        margin-right: -1.5rem;

        img {
            max-width: 115px;
            height: auto;
            max-height: 72px;
        }
    }

    &__text {
        width: 100%;
        font-size: 1rem;
        line-height: 1.4;
        margin-bottom: 3rem;

        @media screen and (max-width: $media-medium) {
            font-size: 1rem;
        }

    }

    &__company-info {
        width: 100%;
        font-size: 0.875rem;
    }

    &__link {
        color: var(--theme-red-color);
        text-decoration: none;
        font-size: 2.65rem;
        position: absolute;
        bottom: 1rem;
        left: 1.5rem;

        &_disabled {
            color: var(--theme-gray-high-light-color);
        }
    }

    &__icon {
        position: relative;
        display: flex;
        justify-content: center;

        &:hover:after {
            opacity: 1;
        }


        &:after {
            opacity: 0;
            position: absolute;
            transition: opacity $default-transition-time;
            background: $default-gradient;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
}