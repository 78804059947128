@import "../variables";
@import "../mixins/text";

.text-block {
    &__title {
        @include blockTitle;
    }

    &__text {
        @include blockSubTitle;
        margin-bottom: 3rem;
        max-width: unset;
        line-height: 1.62;
        color: var(--theme-black-color);
    }

    &__bold {
        font-weight: 400;
    }
}