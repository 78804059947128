@import "../variables";

.footer {
    background-color: var(--theme-gray-color);

    &__content {
        padding: 3rem;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: $media-small) {
            flex-direction: column;
            padding: 1.5rem;
        }
    }

    &__column {
        width: 250px;
        flex-shrink: 0;

        &_first {
            width: 50%;

            @media screen and (max-width: $media-small) {
                order: 2;
            }
        }

        &_last {
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            @media screen and (max-width: $media-small) {
                order: 1;
            }
        }

        @media screen and (max-width: $media-small) {
            width: 100%;
            align-items: center;
            justify-content: center;
            margin-bottom: 1rem;
        }
    }

    &__copyright {
        color: var(--theme-white-color);
        font-size: 0.75rem;
        line-height: 1.5;
        margin-bottom: 1.5rem;

        @media screen and (max-width: $media-small) {
            text-align: center;
        }
    }

    &__links {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        margin-bottom: 1.5rem;

        @media screen and (max-width: $media-small) {
            align-items: center;
            text-align: center;
        }

        a {
            color: var(--theme-white-color);
            font-size: 0.75rem;
            line-height: 1.6;
            transition: color $default-transition-time;

            &:hover {
                text-decoration: none;
                color: var(--theme-red-color);
            }
        }
    }

    &__text {
        color: var(--theme-white-color);
        font-size: 0.75rem;
        line-height: 1.5;

        @media screen and (max-width: $media-small) {
            text-align: center;
        }
    }

    &__address {

    }
}