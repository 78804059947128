@import "../variables";

.portfolio-item {
    margin: 0.5rem 0.5rem 2rem 0.5rem;
    min-height: 100px;
    height: auto;

    &:hover {
        .portfolio-item__description {
            background-color: var(--theme-portfolio-description-hover-color);
        }

        .portfolio-item__title {
            &_dark {
                color: var(--theme-black-color);
            }

            &_light {
                color: var(--theme-white-color);
            }
        }

        .portfolio-item__sub-title {
            &_dark {
                color: var(--theme-black-color);
            }

            &_light {
                color: var(--theme-white-color);
            }
        }

        .portfolio-item__tag {
            &_dark {
                color: var(--theme-gray-color);
            }

            &_light {
                color: var(--theme-gray-high-light-color);
            }
        }
    }

    &__image {
        position: relative;
        display: flex;

        img {
            width: 100%;
            height: auto;
            max-height: 100%;
        }
    }

    &__description {
        padding: 1.25rem;
        background-color: transparent;
        transition: background-color $default-transition-time;
    }

    &__title {
        font-size: 1.125rem;
        font-weight: 700;
        text-transform: uppercase;
        transition: color $default-transition-time;
    }

    &__sub-title {
        font-size: 1.125rem;
        font-weight: 700;
        text-transform: uppercase;
        color: var(--theme-gray-color);
        transition: color $default-transition-time;
    }

    &__tags {
        margin-top: 1.5rem;
    }

    &__tag {
        display: inline-block;
        margin-right: 0.25rem;
        color: var(--theme-portfolio-tags-color);
        font-size: 0.9375rem;
        text-transform: uppercase;
        font-weight: 600;
        text-decoration: none;
        transition: color $default-transition-time;
    }

}