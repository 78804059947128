@import "../variables";
@import "../mixins/text";

.tariffs {

    @media screen and (max-width: $media-large) {
        display: none;
    }

    &_mobile {
        display: none;

        @media screen and (max-width: $media-large) {
            display: block;
        }
    }

    &__title {
        @include blockTitle;
    }

    &__text {
        @include blockSubTitle;
        margin-bottom: 3rem;
    }

    &__wrap {
        justify-content: space-around;

        &_mobile {
            display: flex;
            flex-wrap: wrap;
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        overflow-y: hidden;
        transition: height $default-transition-time;

        &_mobile {
            width: 100%;
            max-width: 340px;
            position: relative;
            padding-bottom: 2.5rem;
            margin-top: 2rem;
        }
    }

    &__column {
        width: calc((100% - 380px) / 5);

        &_first {
            width: 380px;
        }

        &_mobile {
            width: 35%;
            flex-shrink: 0;
        }

        &_mobile-first {
            width: 65%;
            flex-shrink: 0;
        }
    }

    &__line {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        &_dark {
            background-color: var(--theme-tariff-dark-background-color);
        }

        &_light {
            background-color: var(--theme-tariff-light-background-color);
        }

        &_mobile {
            position: absolute;
            bottom: 0;
            left: 0;
            height: 40px;
            width: 100%;
        }
    }

    &__button {
        padding: 0.5rem 0;
        color: var(--theme-red-color);
        text-decoration: underline;
        font-size: 0.875rem;
        cursor: pointer;

        &:hover {
            text-decoration: none;
        }
    }
}