@import "../variables";

.accordion {
    padding: 1.5rem 2rem;
    border-bottom: 1px solid var(--theme-gray-light-color);
    transition: background-color $default-transition-time;

    &:first-of-type {
        border-top: 1px solid var(--theme-gray-light-color);
    }

    &:hover {
        background-color: var(--theme-gray-high-light-color);
    }

    &_open {
        .accordion__icon {
            transform: rotate(45deg);
        }

        .accordion__content {
            max-height: 9999px;
        }
    }

    &__title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        &:hover {
            .accordion__name {
                color: var(--theme-red-color);
            }
        }
    }

    &__name {
        font-size: 1.125rem;
        line-height: 1.6;
        transition: color $default-transition-time;
    }

    &__icon {
        margin-left: 2.5rem;
        font-size: 1.125rem;
        background: $default-gradient;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        transition: transform $default-transition-time;
    }

    &__content {
        overflow: hidden;
        transition: height $default-transition-time;
    }

    &__text {
        padding-top: 1.5rem;
        font-size: 1rem;
        line-height: 1.5;
        color: var(--theme-gray-color);
    }
}