@import "../variables";
@import "../mixins/text";

.work-stages {
    &__title {
        @include blockTitle;
    }

    &__text {
        @include blockSubTitle;
        margin-bottom: 3rem;
    }

    &__wrap {
        background-color: var(--theme-gray-high-light-color);
    }

    &__list {
        padding-top: 3rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 -0.5rem;
    }

    &__item {
        width: 380px;
        flex-shrink: 0;

        @media screen and (max-width: $media-large) {
            width: calc(50% - 1rem);
        }

        @media screen and (max-width: $media-extra-small) {
            width: 100%;
        }
    }
}