@import "../variables";
@import "../mixins/text";

.features {
    margin-bottom: 3.5rem;

    &_text {

        .features__title {
            @include blockTitle;
        }

        .features__text {
            @include blockSubTitle;
            margin-bottom: 3rem;
        }

        .features__list {
            justify-content: space-around;
        }

        .features__item {
            width: 290px;

            @media screen and (max-width: $media-large) {
                width: 40%;
            }

            @media screen and (max-width: $media-extra-small) {
                width: 100%;
            }
        }
    }

    &__title {
        @include blockTitleBig;
        margin-bottom: 2rem;
    }

    &__text {
        @include blockSubTitle;
        margin-bottom: 3rem;
        max-width: unset;

        &_accreditation {
            margin-top: 1.75rem;
            color: var(--theme-black-color);
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 -0.5rem;
    }

    &__item {
        width: 240px;
        flex-shrink: 0;

        @media screen and (max-width: $media-large) {
            width: 33.333333%;
        }

        @media screen and (max-width: $media-extra-small) {
            width: 100%;
        }
    }
}