@import "../variables";
@import "../mixins/text";

.contacts-block {

    &__title {
        @include blockTitle;
    }

    &__text {
        @include blockSubTitle;
        margin-bottom: 3rem;
    }

    &__list {
        display: flex;

        @media screen and (max-width: $media-medium) {
            flex-wrap: wrap;
        }
    }

    &__map {
        width: 50%;

        @media screen and (max-width: $media-medium) {
            order: 1;
            width: 100%;
            height: 300px;
        }

        * {
            touch-action: auto
        }
    }

    &__info {
        width: 50%;
        background-color: var(--theme-gray-high-light-color);
        padding: 8rem 4rem;

        @media screen and (max-width: $media-medium) {
            order: 0;
            width: 100%;
            padding: 2rem;
        }

        a {
            color: var(--theme-red-color);
            text-decoration: none;

            &:hover {
                color: var(--theme-black-color);
            }
        }
    }

    &__item {
        line-height: 1.5;

        &_title {
            @include h5;
            text-transform: uppercase;
            margin-bottom: 1.25rem;
        }

        &_last {
            margin-bottom: 3.5rem;
        }
    }
}