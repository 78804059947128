@import "../variables";

@mixin icon ($iconSymbol) {
    &:before {
        content: $iconSymbol;
    }

    &.icon_animate {
        &:after {
            content: $iconSymbol;
        }
    }
}