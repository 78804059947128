@import "../variables";
@import "../mixins/text";

.tariff {
    display: flex;
    flex-direction: column;
    text-align: center;

    &_mobile {

    }

    &_mobile-first {
        text-align: left;

        .tariff__head {
            text-align: center;
            align-items: center;
        }

        .tariff__title {
            @include h4;
        }

        .tariff__field {
            justify-content: flex-start;
            font-size: 1rem;
        }
    }

    &_first {
        text-align: left;

        .tariff__title {
            @include h4;
            margin-left: 1.4rem;
        }

        .tariff__field {
            justify-content: flex-start;
            font-size: 1rem;
        }
    }

    &:last-child {
        .tariff__line {
            border-right: none;
        }
    }

    &__head {
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: var(--theme-red-color);
        color: var(--theme-white-color);
        border-bottom: 0.5rem solid var(--theme-white-color);

        &_white {
            background-color: var(--theme-white-color);
            border: none;
            color: var(--theme-black-color);
            height: auto;
            align-items: center;
            margin-top: 1.5rem;

            .tariff__title {
                font-size: 1.125rem;
                font-weight: 400;
                text-transform: none;
                margin-bottom: 0.5rem;
            }

            .tariff__price {
                font-size: 1.375rem;
                margin-bottom: 0.25rem;
            }

            .tariff__measure {
                font-size: 0.875rem;
                margin-bottom: 1.5rem;
            }
        }
    }

    &__title {
        @include h5;
        text-transform: uppercase;
    }

    &__button {
    }

    &__price {
        @include h3;
        margin-bottom: 0;

        @media screen and (max-width: $media-large) {
            font-size: 1.5rem;
        }
    }

    &__measure {
        font-size: 0.9375rem;
        text-transform: uppercase;
    }

    &__line {
        background-color: var(--theme-tariff-dark-background-color);

        &_big {
            .tariff__field {
                @include h3;
                margin-bottom: 0;
            }
        }

        &:nth-child(2n + 1) {
            background-color: var(--theme-tariff-light-background-color);
        }
    }

    &__field {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 61px;
        padding: 1rem 1.5rem;
        font-size: 0.875rem;
        border-bottom: 1px solid var(--theme-white-color);
        border-right: 1px solid var(--theme-white-color);

        @media screen and (max-width: $media-large) {
            padding: 0.5rem;
            height: 70px;
        }
    }

    &__field-icon {
        font-size: 1.25rem;
        background: $default-gradient;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        &_gray {
            color: var(--theme-gray-light-color);
            background: unset;
            -webkit-background-clip: unset;
            -webkit-text-fill-color: unset;
        }
    }

    &__hint {
        margin-left: 0.5rem;
    }
}