@import "../variables";

.logo {
    display: flex;
    align-items: center;
    text-decoration: none;

    &_header {
        color: var(--theme-header-color);

        .logo__text {
            fill: var(--theme-header-color);
        }

        .logo__title {
            color: var(--theme-header-color);

            @media screen and (max-width: $media-header-logo-title) {
                display: none;
            }
        }
    }

    &_light {
        color: var(--theme-light-color);

        .logo__text {
            fill: var(--theme-light-color);
        }

        .logo__title {
            color: var(--theme-light-color);
        }
    }

    &_dark {
        color: var(--theme-dark-color);

        .logo__text {
            fill: var(--theme-dark-color);
        }

        .logo__title {
            color: var(--theme-dark-color);
        }
    }

    &__image {
        margin-right: 1.125rem;
    }

    &__icon {
        fill: var(--theme-red-color);
    }

    &__text {
        transition: all $default-transition-time;
    }

    &__title {
        font-weight: 500;
        font-size: 1.375rem;
        text-transform: uppercase;
        line-height: 1.18;
        transition: color $default-transition-time;
    }
}